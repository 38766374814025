import React from "react"
import {Container,Row,Col} from "react-bootstrap";
import Layout from "../components/layout"
import SEO from "../components/seo"
import organizationStyle from '../styles/organization.module.css'
import OrganizationImage from '../components/imagesComponents/OrganizationImage'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'50px'}

const OrganizationPage = () => (
  <Layout>
    <SEO title="Assessment" />
    <body className={organizationStyle.font} >
    <Container style={background}>
      <Row >
        <Col sm={12} md={1}/>
        <Col sm={12} md={5}>
          <div style={{textAlign:'justify'}}>
            <h3 className={organizationStyle.title} style={{color:'#E9BF3C'}}>Organization</h3> 
            <h3 className={organizationStyle.subtitle} style={{color:'#E9BF3C'}}>“There’s no place like home.” –Dorothy</h3>
            <p>Home. It’s our place, comforting and familiar. Adventurous travels are thrilling, but so is getting
            back home. It’s not only familiar, it’s where we keep our treasured memorabilia, our important
            documents, our family pictures, it’s our safe haven, our castle. It’s where we let our guard down.
            So, it’s not surprising that as we grow older, home is exactly the place we’d want to be. Hence
            the term "aging-in-place". The changes in physical capacity could dictate the need to
            accommodate medical devices, clear floor space, minimize belongings, and organize kitchen
            and bathrooms to better facilitate everyday functions. Organizing is at the root of making it all
            work. Our team will develop a plan with you to meet your specific needs and then implement
            your plan for you.</p>
          </div>
        </Col>
        <Col sm={12} md={5}>
          {/* <div className={organizationStyle.boxImage1}>
            <OrganizationImage/>
          </div> */}
          <div style={{ 
                backgroundImage:`url("../../images/organization.jpg")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center',
                backgroundSize:"cover",
                width:'100%'}} className={organizationStyle.img}/>
        </Col>
        <Col sm={12} md={1}/>
      </Row>
      <Row className={"justify-content-md-center"} >
        <Col sm={12} md={10}>
        <p style={{textAlign:'justify'}}>Organization is also an important part of clearing an estate. Heirs may want some items set
            aside or shipped, other household goods are sorted to be donated, sold or disposed of including
            packing and storing belongings if needed. We customize a plan you choose to satisfy the objetive.</p>
        </Col>
        </Row>

      <Row className={"justify-content-md-center"} >
        <Col sm={12} md={5}>
        <div style={{ 
              backgroundImage:`url("../../images/organizationBefore.jpeg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'center',
              backgroundSize:"cover",
              width:'100%',
              height:'300px',
              padding: '5%'
              }} className={organizationStyle.imagebefore}>

                <div className={organizationStyle.caption}>Before</div>
              </div>
        </Col>
        <Col sm={12} md={5}>
        <div style={{ 
              backgroundImage:`url("../../images/organizationAfter.jpg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'center top',
              backgroundSize:"cover",
              width:'100%',
              height:'300px',
              padding: '5%'
              }} className={organizationStyle.imageafter}>
                <div className={organizationStyle.caption}>After</div>
              </div>
        </Col>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default OrganizationPage